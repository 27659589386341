.inter-regular {
  font-family: "Inter", Arial, Helvetica, Arial, system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

.inter-regular-bold {
  @extend .inter-regular;
  font-weight: 700;
}

.inter-h3 {
  font-family: "Inter", Arial, Helvetica, Arial, system-ui;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 3rem;  // 3x16pt = 48px
  line-height: 3.5rem;  // = 56px
}

.inter-h4 {
  font-family: "Inter", Arial, Helvetica, Arial, system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 2.125rem;  // = 34px
  line-height: 2.625rem;  // = 42px
}
