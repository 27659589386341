.landing-page-2 {
  .landing-page-content {
    .item-title {
      margin: 12px 0 3px;
    }

    .item-body {
      margin-left: 0;
    }
  }
}
