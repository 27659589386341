@import '../../resources/colors';
@import '../../resources/variables';

$inputBackground: rgba(255,255,255,.5);

.landing-page-4.landing-page-full {
  background-color: $primary-color;
  padding: 0 30px;
  position: absolute;
  right: 0;
  left: 0;
  overflow: auto;

  .content-box {
    width: calc(100% - 60px);
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border-radius: 25px;
    background-color: white;

    .sub-title {
      color: $primary-color;
      font-size: 30px;
      text-align: center;
    }

    .sub-title-secondary {
      color: $secondary-color;
      font-size: 30px;
      text-align: center;
    }

    .italics {
      font-style: italic;
    }

    .centered {
      text-align: center;


      &.spaced {
        margin-top: 70px;
      }
    }
  }

  .header-panel {
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }

  .decor-logo {
    display:block;
    background-size: contain;
  }

  .header-logo {
    background-size: contain;
    height: 90px;
  }

  .button-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
  .landing-page-4.landing-page-full {
    justify-content: center;

    .decor-logo {
      display: none;
    }

    .header-panel {
      margin-bottom: 0;
    }
  }
}