@import '../../resources/colors';
@import '../../resources/variables';

.centered-container {
  flex: 1;
  flex-direction: column;
  display: flex;
  color: $primary-color;
  align-content: center;
  align-items: center;
}
.main-container{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .centered-container {
    width: 100%;
  }
}
