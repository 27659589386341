@import '../../resources/variables';
@import '../../styles/font';
@import '../../styles/breakpoints';
@import '../../resources/colors';

:export {
  unsubscribe_button_color: $unsubscribe-button-color;
}

.main_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 95%;

  margin: 20px 150px 10px;

  padding-left: 40px;
  padding-right: 40px;

  header {
    flex: 0 1 500px;

    .partner_image {
      object-fit: cover;
      object-position: center;
      width: 280px;
    }
  }

  section {
    flex: 0 1 280px;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;

    header {
      flex: 0 1 30%;
      padding: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    section {
      flex: 0 1 280px;
    }
  }

  @media screen and (max-height: $mobile_sm) {
    header {
      padding-top: 2rem;
    }
  }
}

/*
  Header
 */
.partner_logo {
  object-fit: contain;
  object-position: center;
  min-height: 83px;
  max-height: 335px;
  width: $unsubscribeLogoMediumWidth;

  @media screen and (max-width: $mobile_sm) {
    align-self: center;
    width: $unsubscribeLogoSmallWidth;
  }
}

/*
  Paragraphs
 */
.instruction_section {
  overflow-y: auto;
}

/*
  Button
 */
.unsubscribe_button {
  box-sizing: border-box;
  border-radius: 8px;

  padding: 13px 20px;
  cursor: pointer;

  max-height: 50px;

  @media screen and (max-width: $mobile_sm) {
    width: 100%;
  }

  &_label {
    @extend .inter-regular-bold;
    letter-spacing: 0.25px;

    @media screen and (max-width: $mobile_sm) {
      font-size: 1.125rem;  // 18px;
      letter-spacing: unset;
    }
  }

  &_disabled {
    background-color: lighten($unsubscribe-button-color, 20%);
    cursor: not-allowed;
  }
}

.button_row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: $mobile_sm) {
    align-self: stretch;
    flex-grow: 1;
    align-items: flex-end;
    position: sticky;
    bottom: 0;
  }
}

/*
  Font styles
 */
.heading {
  @extend .inter-regular-bold;
  font-size: 1.5rem;  // 24px
  letter-spacing: -0.2px;

  @media screen and (max-width: $mobile_sm) {
    font-size: 1.125rem;  //18px
    letter-spacing: 0.13px;
    line-height: 2.625rem;  // 42px
  }
}

.body_text {
  @extend .inter-regular;
  line-height: 1.5rem;  // 24px
  font-size: 1.25rem; // 20px
  margin: 0;

  @media screen and (max-width: $mobile_sm) {
    font-size: 1rem;
    letter-spacing: 0.11px;
  }
}

.link_text {
  @extend .body_text;
  color: #2B74AC;
  text-decoration: underline;
}
