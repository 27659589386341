$primary-color: #0F1941;
$secondary-color: #2175D9;
$tertiary-color: #fff;

$primary-text-color: #000;
$secondary-text-color: #9B9B9B;
$tertiary-text-color: $tertiary-color;

$background-opacity: #e3e3e35a;
$input-border-color: #ccc;

$button-bg-color: $primary-color;
$button-text-color: $tertiary-color;
$button-disabled-bg-color: #d1d1d1;
$button-active-bg-color: $secondary-color;

$sj-green-color: #00c3a1;
$sj-blue-color: #00b4e9;

$unsubscribe-button-color: #007CC5;
