@import '/resources/colors';
@import '/resources/variables';
@import './styles/font';

html, body, #root, .App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 320px;
  position: relative;
}

body {
  font-family: $main-font, sans-serif;

  .page-container {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
     & > div {
       height: 100%;
     }

    .standard {
      box-sizing: border-box;
      padding: 14px;
    }
  }

  .registration-page,
  .check-your-email-page {
    min-width: 1280px;

    .content-page,
    .content-marketing{
      min-height: 580px;

      .registration,
      .check-your-email {
        align-self: center;
        background-color: $tertiary-color;
        border-radius: 5px;
        box-sizing: border-box;
        display: flex;
        flex: 2;
        flex-direction: column;
        font-size: 14px;
        margin-top: 25px;
        max-height: 65%;
        max-width: 65%;
        min-width: 500px;
        overflow: hidden;
        padding: 25px;
        width: 550px;
      }

      .title {
        color: $primary-text-color;
        font-size: 24px;
        font-weight: 700;
      }

      .sub-title {
        line-height: 20px;
        margin: 10px 0;
      }

      .button {
        font-size: 17px;
        margin-top: 20px;
        min-width: 100px;
      }
    }

    .content-page {
      display: flex;
      flex: 2;
      flex-direction: column;
      padding: 14px 0;
    }

    .content-marketing {
      background-color: white;
      display: flex;
      flex: 1;
      position: relative;
    }
  }

  .input-field,
  .outlined-basic  {
    input {
      border-radius: 5px;
      box-sizing: border-box;
      font-size: 15px;
      height: 34px;
      line-height: 15px;
      padding: 8px 10px;
    }
  }

  .MuiOutlinedInput-root,
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $input-border-color;
      border-width: 1px;
    }
  }

  button.button {
    background-color: $button-bg-color;
    color: $button-text-color;

    &:active {
      background-color: $button-active-bg-color
    }

    &.Mui-disabled {
      background-color: $button-disabled-bg-color;
    }

    .MuiButton-label {
      text-transform: capitalize;
    }

    &.MuiButton-root:hover {
      background-color: $button-bg-color;
    }
  }
}

.MuiOutlinedInput-root.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
  border-color: $input-border-color;
}

.MuiDialog-root.privacy-policy-modal .MuiDialog-paper.MuiDialog-paperScrollPaper {
  min-width: 240px;
  max-height: 80%;
  max-width: 90%;
  width: 700px
}

.MuiTypography-root.MuiDialogContentText-root {
  padding: 10px 0;
}
