@import '../../resources/colors';
@import '../../resources/variables';

$background: rgba(255,255,255,.85);
$inputBackground: rgba(255,255,255,.5);

.landing-page {
  &.landing-page-with-image {
    background-image: url('/images/assembly-line-worker.jpg');
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
    overflow-y: auto;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .landing-page-header {
    position: relative;
    z-index: 1;
  }

  .landing-page-content {
    background-color: $background;
    border-radius: 10px;
    color: #333;
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    line-height: 26px;
    margin: 10px auto 30px;
    padding: 20px 30px;
    position: relative;
    width: 90%;
    z-index: 1;

    .content-left {
      flex: 0 0 54%;
    }

    .content-right {
      flex: 0 0 36%;
    }

    input {
      background-color: $inputBackground;
    }

    .section {
      margin-bottom: 20px;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      margin: 10px 0;
    }

    .item-title {
      font-weight: bold;
      margin: 5px 0 10px;

    }
    .item-body {
      margin-left: 30px;
    }

    .registration {
      .sub-title {
        margin-bottom: 10px;
      }

      .suggestion-contents {
        background-color: $inputBackground;
      }

      .location-search-input {
        background-color: transparent;
      }

      .privacy-policy-wrapper {
        font-size: 16px;
        width: 90%;
      }

      button.button {
        margin-top: 10px;
        width: 90%;

        &:disabled {
          background-color: #B3CDDA;
        }
      }
    }

    .contact-us {
      margin-top: 30px;
    }
  }

  .down-button{
    bottom: 30px;
    color: $primary-color;
    display: none;
    position: absolute;
    right: 30px;
    z-index: 1;

    &:active {
      color: $secondary-color;
    }

    .foreground {
      position: absolute;
    }

    .background svg {
      color: white;
    }

    svg {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 1100px) {
    .contact-form .input-field.date-picker {
      margin-right: 10px;
    }
  }

    @media screen and (max-width: 900px) {
    .landing-page {
      padding: 0 10px;
    }

    .landing-page-content {
      box-sizing: border-box;
      flex-direction: column;
      font-size: 16px;
      margin-top: 0;
      width: calc(100% - 20px);
    }

    .down-button {
      display: block;
    }

      .contact-form .input-field.date-picker {
        margin-right: 30px;
      }
  }

  @media screen and (max-width: 600px) {
    .landing-page-header .sj-header.centred {
      position: relative;

      .header-title {
        font-size: 30px;
        left: 0;
        padding-left: 20px;
        position: absolute;
        text-align: center;
        top: 80px;
        width: 100%;
      }
    }

    .landing-page-content {
      margin-top: 10px;

      .title {
        font-size: 20px;
      }

      .item-body {
        margin: 0;
      }
    }
  }
}
