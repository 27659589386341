@import '../../../styles/font';
@import '../../../styles/breakpoints';
@import '../../../resources/variables';
@import '../unsubscribePage.module';

$successGreen: rgba(36, 161, 72, 0.54);

.inline_flex_row {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;

  @media screen and (max-width: $mobile_sm) {
    flex-direction: column;
  }
}

.success_tick {
  font-size: 3.75rem;
  color: $successGreen;
  min-height: 60px;
  min-width: 60px;
}

.emphasis_text {
  @extend .inter-h3;

  @media screen and (max-width: $desktop_md) {
    font-size: 1.125rem;  // 18px
    letter-spacing: 0.13px;
    line-height: 1.9375rem; // 31px
  }
}

.partner_logo_override {
  @extend .partner_logo;

  @media screen and (min-width: $mobile_sm) and (min-height: $tablet_height_md) {
    width: $unsubscribeLogoLargeWidth;
  }
}

.subtitle_text {
  @extend .inter-h4;

  @media screen and (max-width: $desktop_md) {
    font-size: 1rem;  // 16px
    letter-spacing: 0.11px;
    line-height: 1.5rem; // 24px
  }
}

/*
  Overriding layout
 */
.main_override {
  gap: 40px;
  max-width: 80.2%;
}

.section_override {
  gap: 20px;
  padding-bottom: 32px;
  overflow-y: auto;

  p {
    margin: 0;
  }
}